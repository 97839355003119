body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato';
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
 
}

code {
  font-family: Lato;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

label {
  font-family: Lato;
}